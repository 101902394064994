<template>
  <div id="custom">
    <div class="custom-box">
      <filter-template
        :filterType="filterType"
        @reset="reset"
        @submit="handleFilter"
        @search="getData"
      ></filter-template>
      <div class="custom-search-result">
        <div class="custom-search-result-title">
          <div>事项列表</div>
          <el-button class="btn" @click="addEvent">新增事项</el-button>
        </div>
        <div class="custom-search-result-tip">
          <i class="el-icon-info active-color"></i>
          <span>查询结果：</span>
          <span class="active-color">{{ total }}个</span>
        </div>
        <matter-table
          :tableTh="tableTh"
          :tableData="tableData"
          :total="total"
          @changePage="changePage"
          :currentPage="page"
          ref="matterTable"
          @cell-click="showMattar"
        >
          <div slot="matter-table">
            <el-table-column :label="'操作'" @cell-click="showMattar">
            </el-table-column>
          </div>
          <div slot="matter-btns" class="dialog-footer btns">
            <div class="btn red">终止事项</div>
            <div class="btn">转成项目</div>
            <div @click="editEvent" class="btn active-color">信息更改</div>
            <!-- <div class="btn">提交审核</div> -->
          </div>
        </matter-table>
      </div>
    </div>
    <matter-add
      :editData="editData"
      @clearEditData="clearEditData"
      ref="matterAdd"
      @createSuccess="getData"
      :edit="edit"
    ></matter-add>
  </div>
</template>

<script>
import FilterTemplate from "../components/filter/filter";
import MatterTable from "./components/table";
import { getMatterList } from "@/api/crm/resource_library";
import { getMatterListTotal } from "@/api/crm/workbench";

import Add from "./add/index";
export default {
  components: {
    FilterTemplate,
    MatterTable,
    "matter-add": Add,
  },
  data() {
    return {
      total: 0,
      itemType: 0,
      pageSize: 1,
      page: 1,
      tableData: [],
      editData: null,
      isNew: true,
      searchVal: "",
      loading: null,
      edit: false,
      filterType: [
        {
          title: "项目性质",
          type: "type",
          checkVal: [],
          disabled: false,
          data: [
            {
              name: "不限",
              value: 0,
            },
            {
              name: "外出",
              value: 1,
            },
            {
              name: "接待",
              value: 2,
            },
          ],
        },
        {
          title: "所属分类",
          type: "class",
          checkVal: [],
          disabled: true,
          data: [
            {
              name: "不限",
              value: 0,
            },
            {
              name: "客户企业",
              value: 1,
            },
            {
              name: "渠道企业",
              value: 2,
            },
          ],
        },
        {
          title: "产业类别",
          type: "industry",
          checkVal: [],
          disabled: true,
          data: [
            {
              name: "不限",
              value: 0,
            },
            {
              name: "石油装备产业",
              value: 1,
            },
            {
              name: "海工装备产业",
              value: 2,
            },
            {
              name: "精密铸造与高端装备产业",
              value: 3,
            },
            {
              name: "生物医药产业",
              value: 4,
            },
            {
              name: "新能源与低碳科技产业",
              value: 5,
            },
          ],
        },
      ],
      tableTh: [
        {
          title: "事项名称",
          type: "itemName",
          className: "active-color line-max-2 clicked",
          width: 250,
        },
        {
          title: "公司/机构/顾问",
          type: "resName",
          className: "line-max-2",
          width: 200,
        },
        {
          title: "时间",
          type: "time",
          className: "",
          headerAlgin: "center",
          width: "140",
        },

        {
          title: "事项人员",
          type: "cstName",
          className: "",
          width: "160",
        },
        {
          title: "陪同人员",
          type: "receptionist",
          className: "line-max-2",
        },
      ],
    };
  },
  filters: {
    itemTypeFilter(status) {
      switch (status) {
        case 1:
          return "外出";
        case 2:
          return "接待";
      }
    },
  },
  mounted() {
    this.itemType = this.$route.query.tab ? this.$route.query.tab : 0;
    this.getData();
    if (this.$route.query.tab) {
      this.filterType[0].checkVal.push(Number(this.$route.query.tab));
    }
  },
  methods: {
    reset() {
      this.filterType.forEach((val) => {
        val.checkVal = [];
      });
    },
    clearEditData() {
      this.$set(this, "editData", null);
    },
    editEvent(data) {
      this.$set(
        this,
        "editData",
        this.$refs.matterTable.$refs.matter.matterData
      );
      this.$refs.matterTable.$refs.matter.close();
      this.edit = true;
      this.$refs.matterAdd.open(true);
    },
    addEvent() {
      //新增事项
      this.edit = false;
      this.$set(this, "editData", null);
      this.$refs.matterAdd.open();
    },
    handleFilter(val) {
      this.itemType = val;
      this.page = 1;
      this.getData();
    },
    getData(searchVal) {
      this.$set(this, "editData", null);
      if (searchVal) {
        this.tableData = [];
        this.page = 1;
      }
      getMatterList(`${this.page}/10`, {
        resId: 0,
        itemType: this.itemType,
        keyword: searchVal ? searchVal : "",
      }).then((res) => {
        this.tableData = res.data;
        this.loading.close();
      });
      this.getMatterListTotal(searchVal);
    },
    getMatterListTotal(searchVal) {
      getMatterListTotal({
        itemType: this.itemType,
        keyword: searchVal ? searchVal : "",
      }).then((res) => {
        this.total = res.data;
        this.loading.close();
      });
    },
    changePage(val) {
      this.page = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
#custom {
  background: #f3f7ff;
  padding: 25px 18px;
  box-sizing: border-box;
  .custom-box {
    background: #fff;
  }
}
::v-deep .el-input-group__append {
  background: #00a0e9;
  color: #fff;
  border-color: #00a0e9;
  border-radius: 0;
}
.custom-search-result {
  padding: 0 24px;
  .custom-search-result-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
  .custom-search-result-tip {
    color: #374567;
    font-size: 14px;
    padding: 9px 22px;
    background: #e6f7ff;
    border: 1px solid #bae7ff;
    margin: 20px 0 16px;
    i {
      margin-right: 9px;
      vertical-align: middle;
    }
  }
}
.el-row {
  padding: 20px 0;
}
</style>