<template>
  <div class="audit">
    <div class="title" :class="required ? 'required' : ''">{{ title }}</div>
    <div class="audit-person">
      <span
        class="nameAvatar bg-active-color"
        v-for="item in auditUser"
        :key="item"
        >{{ item }}</span
      >
      <span class="addIcon" @click="addAudit"
        ><img src="@/assets/images/workbench/audit-add-icon.png" alt=""
      /></span>
    </div>
    <user-select :visible='visible'></user-select>
  </div>
</template>

<script>
import UserSelect from "@/components/user_select.vue";
export default {
  props: {
    title: {
      type: String,
      default: "内审员",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  data(){
      return{
          auditUser:[],
          visible:false
      }
  },
  components: {
    UserSelect
  },
  methods: {
    addAudit() {
        this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.audit {
  margin-bottom: 16px;
  .title {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  .nameAvatar {
    display: inline-block;
    font-size: 11px;
    color: #fff;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    text-align: center;
    line-height: 33px;
    margin-right: 27px;
  }
  .audit-person {
    display: flex;
  }
  .addIcon {
    width: 33px;
    height: 33px;
    margin-right: 27px;
    img {
      width: 100%;
      vertical-align: middle;
    }
  }
  .required:before {
    content: "*";
    color: #f56c6c;
    margin-right: 0.027778rem;
  }
}
</style>