<template>
  <el-dialog
    :destroy-on-close="true"
    title="新增事项"
    :visible.sync="dialogVisible"
    @close='close'
  >
    <div class="dialog-content">
      <el-form :model="formData" ref="formData" label-width="150px">
        <div class="flex">
          <el-form-item
            class="flex-1"
            label="事项名称："
            :label-width="'130px'"
            prop="matterInfo.itemName"
            :rules="[{ required: true, message: '项目名称不能为空' }]"
          >
            <el-input v-model="formData.matterInfo.itemName"></el-input>
          </el-form-item>
          <el-form-item
            class="flex-1"
            :label-width="'130px'"
            prop="matterInfo.itemName"
            :rules="[{ required: true, message: '项目名称不能为空' }]"
          >
          </el-form-item>
        </div>
        <add-project-info
          :data="formData.matterInfo"
          :enterprise="formData.lstEnterprise"
          @addRecommend="changeForm"
        ></add-project-info>
        <add-linkman
          :data="formData.lstCutomer"
          @addLinkman="changeForm"
          @removeLinkman="removeForm"
        ></add-linkman>
        <add-attachment
          :data="formData.lstRecord"
          @addAttachment="changeForm"
          @removeAttachment="removeForm"
          @isClick="isClickEvent"
          ref="attachment"
        ></add-attachment>
        <div class="reception-type">
          <el-form-item
            label="接待等级："
            prop="matterInfo.receptionType"
            label-width="100"
            :rules="[{ required: true, message: '接待等级不能为空' }]"
          >
            <el-radio-group v-model="formData.matterInfo.receptionType">
              <el-radio :label="1">一般接待</el-radio>
              <el-radio :label="2" disabled>重要接待</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        :disabled="isClick ? false : true"
        type="primary"
        @click="submitEvent"
        >确认</el-button
      >
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import LinkMan from "@/views/workbench_module/attract_project/components/add/linkMan";
import ProjectInfo from "./projectInfo";
import Attachment from "./attachment";
import { itemCreate, itemUpdate } from "@/api/crm/workbench";
import Audit from "@/views/workbench_module/components/audit/index";
export default {
  props: {
    editData: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      isClick: true,
      data: {
        lstEnterprise: [
          {
            entName: "", //全称
          },
        ],
        matterInfo: {
          itemName: "",
          receptionist: "",
          channelSource: "",
          channelSourceDesc: "",
          industry: "",
          industryDesc: "",
          itemType: 1,
          remark: 1,
          evaluation: "",
          address: "",
          receptionType: 1,
        },
        lstCutomer: [
          {
            cstName: "",
            position: "",
            tel: "",
          },
        ],
        lstRecord: [
          {
            time: "",
            reason:"",
            lstAttachment: [],
          },
        ],
      },
    };
  },
  computed: {
    formData() {
      if (this.editData) {
        return this.editData;
      }
      return this.data;
    },
  },
  mounted(){
  },
  components: {
    "add-linkman": LinkMan,
    "add-project-info": ProjectInfo,
    "add-attachment": Attachment,
    "add-audit": Audit,
  },
  methods: {
    isClickEvent(bool) {
      this.isClick = bool;
    },
    removeForm(type) {
      if (!isNaN(type.index) && type.child) {
        this.formData[type.name][type.index][type.child].splice(type.i, 1);
        return;
      } else if (type.child) {
        this.formData[type.name][type.child].splice(type.i, 1);
      } else {
        this.formData[type.name].splice(type.i, 1);
      }
    },
    changeForm(type) {
      if (!isNaN(type.index) && type.child) {
        this.formData[type.name][type.index][type.child].push(type.data);
        return;
      } else if (type.child) {
        this.formData[type.name][type.child].push(type.data);
      } else {
        this.formData[type.name].push(type.data);
      }
    },
    async submitEvent() {
      const reqData = {
        lstEnterprise: this.formData.lstEnterprise,
        ...this.formData.matterInfo,
        lstCutomer: this.formData.lstCutomer,
        lstRecord: this.formData.lstRecord,
      };
      try {
        if (this.edit) {
          await itemUpdate(reqData);
        } else {
          await itemCreate(reqData);
        }
        this.close();
        this.$emit("createSuccess");
      } catch (error) {
        console.error(error);
      }
    },
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.$refs["formData"].resetFields();
      this.$emit('clearEditData')
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  padding: 24px 0;
  border-top: 1px solid #e1e6f0;
  .el-form {
    width: 80%;
    margin: 0 auto;
  }
  .addBtn {
    height: 30px;
    line-height: 30px;
    margin-top: 0;
    padding: 0;
  }
  .company-info {
    .company-introduce {
      margin-bottom: 10px;
    }
  }
  .reception-type {
    margin-top: 10px;
  }
}
::v-deep .el-input__inner {
  height: 30px !important;
}
::v-deep .el-dialog {
  width: 70%;
  .el-dialog__body {
    padding: 0;
  }
}
::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-radio__inner {
  border-radius: 2px;
}
::v-deep .el-radio__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 0.006944rem solid #fff;
  border-left: 0;
  border-top: 0;
  height: 0.048611rem;
  left: 0.027778rem;
  position: absolute;
  top: 0.006944rem;
  background: transparent;
  border-radius: 0;
  -webkit-transform: rotate(45deg) scaleY(0);
  transform: rotate(45deg) scaleY(0);
  width: 0.020833rem;
  -webkit-transition: -webkit-transform 0.15s ease-in 0.05s;
  transition: -webkit-transform 0.15s ease-in 0.05s;
  transition: transform 0.15s ease-in 0.05s;
  transition: transform 0.15s ease-in 0.05s,
    -webkit-transform 0.15s ease-in 0.05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}
::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  -webkit-transform: rotate(45deg) scaleY(1);
  transform: rotate(45deg) scaleY(1);
}
</style>