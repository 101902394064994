<template>
  <el-dialog
    :visible="visible"
    :append-to-body="true"
    :title="title"
    width="918px"
  >
    <div class="border">
      <wk-dep-user-view
        :props="props"
        :dep-value="deps"
        :user-value="users"
        @change="selectChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click.native="close">取消</el-button>
      <el-button @click="handleConfirm" type="primary">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import WkDepUserView from "./dep";
export default {
  // 弹窗选择员工
  name: "WkDepUserViewDialog",
  components: {
    WkDepUserView,
  },
  mixins: [],
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: true,
    },
    // 取值字段
    props: {
      type: Object,
      default: () => {
        return {
          userOptions: [
            {
              realname: "张某",
              isCheck: true,
              userId: 1,
            },
            {
              realname: "王某",
              isCheck: true,
              userId: 2,
            },
            {
              realname: "赵某",
              isCheck: true,
              userId: 3,
            },
          ],
        };
      },
    },
    closeOnEmptyValue: {
      type: Boolean,
      default: true,
    },
    depValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    userValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      title: "添加审核人",
      users: [],
      deps: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    selectChange(users, deps) {
      this.users = users;
      this.deps = deps;
    },
    /**
     * 点击确定
     */
    handleConfirm() {
      this.$emit("change", this.users, this.deps);
      if (this.closeOnEmptyValue) {
        if (this.users.length !== 0 || this.deps.length !== 0) {
          this.close();
        }
      } else {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__wrapper {
  /deep/ .el-dialog__body {
    border-top: 1px solid #c0c8da;
  }
}
</style>